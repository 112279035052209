<template>
<div>
  <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="true"></loading>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
    <v-flex md12>
      <v-alert
        :value="mappingStatus"
        :type="mappingStatusType"
      >
        Последненяя синхронизация: <span v-if="mappingStatus">{{ mappingStatus.time | date }} {{ mappingStatus.time | time }} ({{ mappingStatus.status }})</span>
        <span v-else>Загружается...</span>
         <v-btn dark @click="getVendingMachineStatus">Обновить</v-btn>
      </v-alert>
    </v-flex>
      <v-flex
        md12
      >
        <material-card
          color="green"
          :title="machine.name"
          :text="machine.serialVendingNumber"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr v-bind:class="{ 'light-blue lighten-5': item.status === 'OutOfStock', 'red lighten-5': item.status === 'Blocked'}">
                <td>
                  <v-btn title="Редактировать мэппинг" color="primary" small flat icon @click="pickToEditMapping(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  
                </td>
                <td>
                  {{ item.cell }}
                </td>
                <td>{{ item.name }}</td>
                <td><b style="font-size: 14px">{{ item.currentAmount }}</b> / <span style="font-size: 12px">{{ getCellCapacityByCell(item.cell) }}</span></td>
                <td>{{ getPriceFromDeviceShopItem(item.vendingMachineShopItemId) }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.status }}</td>
                <td>{{ getActionFromDeviceShopItem(item.vendingMachineShopItemId) }}</td>
                <td>{{ item.categoryName }} </td>
                <td>{{ getButtonFromDeviceShopItem(item.vendingMachineShopItemId) }}</td>
                <td>
                  <v-btn title="Редактировать мэппинг" color="primary" small flat icon @click="pickToEditMapping(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn color="error" small flat icon @click="pickToDeleteMapping(item)" >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      
    </v-layout>
  </v-container>
  <input
      ref="file"
      type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      style="display:none"
      value="Выберите файл"
      @change="handleFileUpload()" >
  <v-container fluid>
      <v-layout row wrap>
          <v-btn title="Добавить новый мэппинг" color="#66bb6a" @click="addMappingDialog = true">Добавить</v-btn>
          <v-btn title="Меню торгового автомата" color="primary" :to="{ name: 'Меню торгового автомата', params: { id: id }}">Меню</v-btn>
          <v-btn title="Задание для торгового автомата" color="primary" :to="{ name: 'Задание для торгового автомата', params: { id: id }}">Задание</v-btn>
          <v-btn title="Ячейки" color="primary" :to="{ name: 'Ячейки торгового автомата', params: { id: id }}">Ячейки</v-btn>
          <v-btn v-if="$auth.ready() && $auth.check(['admin'])" title="Обновить мэппинг и синхронизировать с Блокчейн"  color="primary" @click="pickToSyncMapping">Обновить мэппинг</v-btn>
          <v-btn v-if="$auth.ready() && $auth.check(['admin'])" title="Назначить текущее количество в количество"  color="primary" @click="pickToSyncMapping">Разблокировать ячейки</v-btn>
          <v-btn v-if="$auth.ready() && $auth.check(['admin'])" title="Загрузить все ячейки по их максимальной емкости"  color="primary" @click="setFullAmount">Загрузить по максимуму</v-btn>
          <v-btn v-if="$auth.ready() && $auth.check(['admin'])" title="Перезагрузить терминал" color="primary" @click="pickToResetDevice">Перезагрузить терминал</v-btn>
          <!-- <v-btn
            title="Загрузить карты с заказами"
            color="#66bb6a"
            @click="launchFilePicker">
            Загрузить карты с заказами
          </v-btn> -->
      </v-layout>
    </v-container>
  <v-dialog v-model="addMappingDialog" max-width="600">
      
        <v-card>
            <v-toolbar flat color="#66bb6a">
              <v-toolbar-title left color="blue" class="text--white">Новый мэппинг</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="addMappingDialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-card-text>
            <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-form ref="form" v-model="valid">
                          
                          
                <v-layout wrap>
                    <v-flex xs12 sm4 class="font-weight-bold">Позиция меню</v-flex>
                    <v-flex xs12 sm8>
                        <v-autocomplete
                          label="Выберите позицию из меню"
                          v-model="newMap.vendingMachineShopItemId"
                          :items="deviceShopitems"
                          item-text="name"
                          item-value="id"
                          @change="onChange"
                          :rules="rules.required"
                        >
                          <template v-slot:item="item">
                            <v-chip
                            >
                              <v-avatar>
                                <img :src="imageUrl(item.item.image)">
                              </v-avatar>
                              {{ item.item.name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                    </v-flex>
                </v-layout>
                
                <v-layout wrap>
                    <v-flex xs12 sm4 class="font-weight-bold">Ячейка</v-flex>
                    <v-flex xs12 sm8>
                        <v-select label="Выберите ячейку"
                                  :items="cells"
                                  :rules="rules.required"
                                  item-text="name"
                                  item-value="name"
                                  v-model="newMap.cell"></v-select>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex xs12 sm4 class="font-weight-bold">Текущее количество</v-flex>
                    <v-flex xs12 sm8>
                         <v-text-field :rules="rules.required"
                                  label="Количество" 
                                  v-model="newMap.amount" 
                                  type="number"></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="addMapping" class="white--text" :disabled="!valid || isSaving">Сохранить</v-btn>
                    <v-btn color="red darken-1" @click.native="addMappingDialog = false" class="white--text">Отмена</v-btn>
                </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
  <v-dialog v-model="editMappingDialog" max-width="600">
    <v-card>
        <v-toolbar flat color="#66bb6a">
          <v-toolbar-title left color="blue" class="text--white">Обновить мэппинг</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editMappingDialog = false">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
            <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>          
                      
            <v-layout row wrap py-1>
                <v-flex xs12 sm4 class="font-weight-bold">Товар</v-flex>
                <v-flex xs12 sm8>
                    <!-- <v-select label="Выберите товар"
                              :items="shopItems"
                              :rules="rules.required"
                              item-text="name"
                              item-value="id"
                              disabled
                              v-model="editMap.shopItemId"></v-select> -->
                  <v-autocomplete
                    label="Выберите товар"
                    v-model="editMap.vendingMachineShopItemId"
                    :items="deviceShopitems"
                    item-text="name"
                    item-value="id"
                    @change="onChangeForEdit"
                    single-line
                    solo
                    hide-details
                    :rules="rules.required"
                  >
                    <template v-slot:item="item">
                      <v-chip
                      >
                        <v-avatar>
                          <img :src="imageUrl(item.item.image)">
                        </v-avatar>
                        {{ item.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-flex>
            </v-layout>
            
            <!-- <v-layout row wrap py-1>
                <v-flex xs6 sm6 class="font-weight-bold">Цена</v-flex>
                <v-flex xs6 sm6 class="font-weight-bold">Кнопка</v-flex>
                <v-flex xs6 sm6>
                      <v-text-field
                        single-line
                        solo
                        hide-details
                        label="Выберите цену" 
                        v-model="editMap.price" 
                        suffix='₽'
                        type="number"></v-text-field>
                </v-flex>
                
                <v-flex xs6 sm6>
                  <v-select label="Выберите ячейку"
                    :items="cells"
                    :rules="rules.required"
                    single-line
                    solo
                    hide-details
                    item-text="name"
                    item-value="name"
                    disabled
                    v-model="editMap.cell"></v-select>
                </v-flex>
            </v-layout> -->

            
            
            <v-layout row wrap>
                <v-flex xs12 sm4 class="font-weight-bold">Кнопка</v-flex>
                <v-flex xs12 sm8>
                  <v-select label="Выберите ячейку"
                    :items="cells"
                    :rules="rules.required"
                    single-line
                    solo
                    hide-details
                    item-text="name"
                    item-value="name"
                    disabled
                    v-model="editMap.cell"></v-select>
                </v-flex>
            </v-layout>

            <v-layout wrap py-1>
                <v-flex xs12 sm4 class="font-weight-bold">Текущее количество</v-flex>
                <v-flex xs12 sm8>
                  <v-text-field 
                    label="Текущее количество в автомате" 
                    v-model="editMap.currentAmount" 
                    single-line
                    solo
                    hide-details
                    type="number">
                    <template v-slot:prepend>
                      <v-icon color="#000"
                              @click="removeCurrentAmount">
                          mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append-outer>
                      <v-icon color="#000"
                              @click="addCurrentAmount">
                          mdi-plus
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout wrap py-1>
                <v-flex xs12 sm4 class="font-weight-bold">Загружаю</v-flex>
                <v-flex xs12 sm8>
                      <v-text-field 
                        label="Загружаю" 
                        v-model="editMap.addedAmount" 
                        single-line
                        solo
                        hide-details
                        type="number">
                      <template v-slot:prepend>
                          <v-icon color="#000"
                                  @click="removeAmount">
                              mdi-minus
                          </v-icon>
                      </template>

                      <template v-slot:append-outer>
                        <v-icon color="#000"
                              @click="addAmount">
                          mdi-plus
                        </v-icon>
                      </template>
                      </v-text-field>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" @click="editMapping" class="white--text" :disabled="!valid || isSaving">Сохранить</v-btn>
                <v-btn color="red darken-1" @click.native="editMappingDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="deleteMappingDialog" max-width="600">
    
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Удаление мэппинга</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="deleteMappingDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            Вы действительно хотите удалить мэппинг для {{ editMap.name }}?
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="deleteMapping" class="white--text" :disabled="isSaving">Удалить</v-btn>
            <v-btn color="red darken-1" @click.native="deleteMappingDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="resetReaderDialog" max-width="600">
    <v-card>
        <v-toolbar flat color="#66bb6a">
          <v-toolbar-title left color="blue" class="text--white">Перезагрузить терминал</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="resetReaderDialog = false">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      <v-card-text>
        <v-layout wrap>
          Вы действительно хотите перезагрузить этот терминал?
        </v-layout>
        <v-layout wrap>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="resetReader" class="white--text" :disabled="isSaving">Перезагрузить</v-btn>
          <v-btn color="red darken-1" @click.native="resetReaderDialog = false" class="white--text">Отмена</v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="syncMappingDialog" max-width="600">
    <v-card>
        <v-toolbar flat color="#66bb6a">
          <v-toolbar-title left color="blue" class="text--white">Обновить мэппинг</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="syncMappingDialog = false">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      <v-card-text>
        <v-layout wrap>
          Вы действительно хотите обновить мэппинг в терминале? Данная операция должна выполняться не чаще раза в минуту.
        </v-layout>
        <v-layout wrap>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="syncMapping" class="white--text" :disabled="isSaving">Обновить мэппинг</v-btn>
          <v-btn color="red darken-1" @click.native="syncMappingDialog = false" class="white--text">Отмена</v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data: () => ({
    isLoading: false,
    addMappingDialog: false,
    editMappingDialog: false,
    deleteMappingDialog: false,
    resetReaderDialog: false,
    syncMappingDialog: false,
    isSaving: false,
    machine: {},
    headers: [
      {
        sortable: false,
        text: ''
      },
      {
        sortable: true,
        text: '▦',
        value: 'cell'
      },
      {
        sortable: true,
        text: 'Товар',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Текущее кол-во',
        value: 'currentAmount'
      },
      {
        sortable: true,
        text: 'Цена',
        value: 'price'
      },
      {
        sortable: true,
        text: 'Кол-во',
        value: 'amount'
      },
      {
        sortable: true,
        text: 'Статус',
        value: 'status'
      },
      {
        sortable: true,
        text: 'Действие',
        value: 'command'
      },
      {
        sortable: true,
        text: 'Категория',
        value: 'categoryName'
      },
      {
        sortable: true,
        text: 'Кнопка из меню',
        value: 'categoryName'
      },
      {
        sortable: false,
        text: ''
      }
    ],
    newMap: {
      shopItemId: null,
      vendingMachineId: null,
      vendingMachineShopItemId: null,
      amount: 0,
      cell: 0,
    },
    editMap: {
      id: null,
      shopItemId: null,
      vendingMachineId: null,
      vendingMachineShopItemId: null,
      amount: 0,
      cell: 0,
      addedAmount: 0
    },
    items: [],
    shopItems: [],
    deviceShopitems: [],
    categoryItems: [],
    commands: [{ id: "", name: null, title: "<нет>" }],
    actions: [{ id: "", name: null, title: "<нет>" }],
    cells: [],
    rules: {
        required: [v => !!v || 'Поле обязательно',],
    },
    valid: false,
    error: null,
    id: null,
    mappingStatus: null,
    resetReaderItem: null
  }),
  components: {
    Loading
  },
  computed: {
    prices() {
      let array = [...new Set(this.cells.filter(x=>x.active == true).map(x=>x.price).sort(this.sortCompareNumbers))]
      console.log(array)
      return array
    },
    fltrdCellsAdd (){
      return this.cells.filter(x=>x.price == this.newMap.price && x.capacity >= this.newMap.amount && !this.mappingItemsCells.includes(x.name)).sort(this.sortFunction)
    },
    fltrdCellsEdit(){
      return this.cells.filter(x=>x.price == this.editMap.price && x.capacity >= this.editMap.amount).sort(this.sortFunction)
    },
    mappingItemsCells(){
      return [...new Set(this.items.map(x=>x.cell))]
    },
    mappingStatusType(){
      if (this.mappingStatus){
        if (this.mappingStatus.status == 'Success')
          return 'success'
        else
          return 'error'
      }

      return 'info';
    }
  },
  created (){
    this.id = this.$route.params.id;
    this.newMap.vendingMachineId = this.id
  },
  async mounted () {
    await this.getCategories()
    // await this.getCommands()
    await this.getActions()
    this.getVendingMachine()
    this.getShopItems()
    this.getVendingMachineShopItems()
    this.getVendingMachineItems()
    this.getVendingMachineCells()
    this.getVendingMachineStatus()
  },
  methods: {
    commandTitle(commandName){
      if (commandName){
        if (this.commands && this.commands.length > 0){
          const cmd = this.commands.find(x=>x.name == commandName)
          if (cmd) {
            return cmd.title
          }
        }
      }

      return '<нет>';
    },
    actionTitle(actionName){
      if (actionName){
        if (this.actions && this.actions.length > 0){
          const cmd = this.actions.find(x=>x.name == actionName)
          if (cmd) {
            return cmd.title
          }
        }
      }

      return '<нет>';
    },
    getPriceFromDeviceShopItem(deviceShopItemId) {
      if (deviceShopItemId){
        if (this.deviceShopitems && this.deviceShopitems.length > 0){
          const deviceShopItem = this.deviceShopitems.find(x=>x.id == deviceShopItemId)
          if (deviceShopItem) {
            return deviceShopItem.price
          }
        }
      }

      return '?';
    },
    getButtonFromDeviceShopItem(deviceShopItemId) {
      if (deviceShopItemId){
        if (this.deviceShopitems && this.deviceShopitems.length > 0){
          const deviceShopItem = this.deviceShopitems.find(x=>x.id == deviceShopItemId)
          if (deviceShopItem) {
            return deviceShopItem.button
          }
        }
      }

      return '?';
    },
    getActionFromDeviceShopItem(deviceShopItemId) {
      if (deviceShopItemId){
        if (this.deviceShopitems && this.deviceShopitems.length > 0){
          const deviceShopItem = this.deviceShopitems.find(x=>x.id == deviceShopItemId)
          if (deviceShopItem) {
            return this.actionTitle(deviceShopItem.action)
          }
        }
      }

      return '?';
    },
    imageUrl: function (imgUrl) {
      return process.env.VUE_APP_IMAGE_URL + imgUrl
    },
    getCategoryName(id){
      let category = this.categoryItems.find(x=>x.id == id)

      return category ? category.name : '?'
    },
    getVendingMachine (){
      this.$http.get('/vendingmachines/' + this.id)
        .then(response => {
          this.machine = response.data
        })
    },
    getVendingMachineItems(){
      this.$http.get('/vendingmachines/' + this.id + '/items' )
        .then(response => {
          this.items = response.data.map(x => { x.addedAmount = 0; return x}) 
        })
    },
    getVendingMachineShopItems(){
      this.$http.get('/vendingmachines/' + this.id + '/shop-items' )
        .then(response => {
          this.deviceShopitems = response.data; 
        })
    },
    getShopItems(){
      this.$http.get('/shopitems')
        .then(response => {
          this.shopItems = response.data
        })
    },
    getVendingMachineCells(){
      this.$http.get('/vendingmachines/' + this.id + '/cells' )
        .then(response => {
          this.cells = response.data
          this.cells = this.cells.sort(this.sortFunction)
        })
    },
    getCellCapacityByCell(cellName){
      let cell = this.cells.find(x=>x.name == cellName)
      return cell ? cell.capacity : '?'
    },
    getVendingMachineStatus(){
      this.$http.get('/vendingmachines/'+ this.id + '/status' ).
        then(response => {
          this.mappingStatus = response.data
        }).
        catch(e => {
          //this.mappingStatusText = e.response.data;
          console.log(e)
        })
    },
    onChange(selectedItemId) {
      this.newMap.vendingMachineShopItemId = selectedItemId
      const deviceShopitem = this.deviceShopitems.find(x => x.id === selectedItemId)
      this.newMap.shopItemId = deviceShopitem.shopItemId
    },
    onChangeForEdit(selectedItemId) {
      this.editMap.vendingMachineShopItemId = selectedItemId
      const deviceShopitem = this.deviceShopitems.find(x => x.id === selectedItemId)
      console.log(selectedItemId, deviceShopitem)
      this.editMap.shopItemId = deviceShopitem.shopItemId
    },
    addMapping(){
      this.error = null;
      this.isSaving = true;
      this.$http.post('/vendingmachines/' + this.id + '/items', this.newMap).
          then(response => {
            this.addMappingDialog = false;
            this.newMap = {
              shopItemId: null,
              vendingMachineId: this.id,
              amount: 0,
              cell: 0,
              price: 0
            };
            this.getVendingMachineItems();
            this.cells = this.cells.sort(this.sortFunction)
            this.isSaving = false;
          }).
          catch(e => {
            this.error = e.response.data;
            console.log(e)
            this.isSaving = false;
          });
      
    },
    pickToEditMapping(item){
      this.error = null;
      this.isSaving = false;
      item.addedAmount = 0;
      this.editMap = item;
      this.editMap.addedAmount = Number(0);
      this.editMappingDialog = true;
    },
    pickToDeleteMapping(item){
      this.editMap = item;
      this.deleteMappingDialog = true;
      this.isSaving = false;
    },
    pickToResetDevice(item){
      this.resetReaderItem = item;
      this.resetReaderDialog = true;
      this.isSaving = false;
    },
    pickToSyncMapping(){
      this.syncMappingDialog = true;
    },
    editMapping(){
      this.error = null;
      this.isSaving = true;
      let newAmount = this.editMap.currentAmount*1 + this.editMap.addedAmount*1
      
      if (newAmount <= this.getCellCapacityByCell( this.editMap.cell)){
        this.editMap.amount = newAmount
        this.editMap.currentAmount = this.editMap.amount
        this.$http.put('/vendingmachines/' + this.id + '/items/' + this.editMap.id, this.editMap).
          then(response => {
            this.editMappingDialog = false;
            this.getVendingMachineItems();
            this.cells = this.cells.sort(this.sortFunction)
            this.isSaving = false;
          }).
          catch(e => {
            this.error = e.response.data;
            console.log(e)
            this.isSaving = false;
          });
      }
      else {
        this.error = "Емкость ячейки меньше количества текущего и загружаемого товара в этой ячейки. Исправьте текущее или количество загружаемоего товара"
      }

      
    },
    deleteMapping(){
      this.isSaving = true;
      this.$http.delete('/vendingmachines/' + this.id + '/items/' + this.editMap.id).
        then(response => {
              this.deleteMappingDialog = false
              this.editMap = {};
              this.getVendingMachineItems();
              this.cells = this.cells.sort(this.sortFunction)
              this.isSaving = false;
            }).
            catch(e => {
              console.log(e)
              this.isSaving = false;
            });
    },
    resetReader(){
      this.isSaving = true;
      this.$http.delete('/vendingmachines/' + this.id + '/reset/').
        then(response => {
          this.resetReaderDialog = false
          this.resetReaderItem = null;
          this.isSaving = false;
        }).
        catch(e => {
            console.log(e)
            this.isSaving = false;
        });
    },
    syncMapping(){
      this.isSaving = true;
      this.$http.put('/vendingmachines/' + this.id + '/items').
        then(response => {
          this.isSaving = false;
          this.syncMappingDialog = false
          this.getVendingMachineItems()
        }).
        catch(e => {
            console.log(e)
        });
    },
    setCurrentAmount(){
      this.$http.put('/vendingmachines/' + this.id + '/items/amount').
        then(response => {
          console.log(response.data)
          this.getVendingMachineItems()
        }).
        catch(e => {
            console.log(e)
        });
    },
    setFullAmount(){
      this.$http.put('/vendingmachines/' + this.id + '/items/full').
        then(response => {
          console.log(response.data)
          this.getVendingMachineItems()
        }).
        catch(e => {
            console.log(e)
        });
    },
    sortFunction(a,b){
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    },
    sortCompareNumbers(a,b){
      return a - b;
    },
    changeEditedPrice(price){
      this.editMap.cell = this.fltrdCellsEdit[0].name
    },
    changeAddedPrice(price){
      this.newMap.cell = this.fltrdCellsAdd[0].name
    },
    async getCategories(){
      await this.$http.get('/ShopItemCategory')
        .then(response => {
          this.categoryItems = response.data
        })
    },
    async getCommands(){
      await this.$http.get('/commands')
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.commands.push(...response.data)
          }
        })
    },
    async getActions(){
      await this.$http.get('/cellactions')
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.actions.push(...response.data)
          }
        })
    },
    removeCurrentAmount() {
      if (this.editMap.currentAmount > 0)
        this.editMap.currentAmount -= 1;
    },
    addCurrentAmount() {
      this.editMap.currentAmount += 1;
    },
    removeAmount() {
      if (this.editMap.addedAmount > 0)
        this.editMap.addedAmount -= 1;
    },
    addAmount() {
      this.editMap.addedAmount += 1;
    },
    launchFilePicker () {
      this.$refs.file.click()
    },
    handleFileUpload () {
      let formData = new FormData()
      formData.append('file', this.$refs.file.files[0])
      this.isLoading = true
      this.$http.post('VendingProfiles/bulk/devices/' + this.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          this.isLoading = false
          this.getUsers()
        }).catch(e => {
          this.isLoading = false
          console.log(e)
          this.error = e.response.data
        })
    },
  }
}
</script>

<style>
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 40px;
}
</style>